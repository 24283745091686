/* src/components/Footer.css */
.footer {
  background-color: #2c3e50;
  color: white;
  padding: 40px 20px;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: flex-start;
}

.footer-description {
  flex: 1;
  margin: 10px;
}

.footer-contact,
.footer-links,
.footer-newsletter {
  flex: 1;
  margin: 10px;
}

.footer h1, .footer h3 {
  color: #ffffff;
  margin-bottom: 15px;
}

.footer p {
  color: #cccccc;
}

.footer-links ul {
  list-style-type: none;
  padding: 0;
}

.footer-links ul li {
  margin-bottom: 10px;
}

.footer-links ul li a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-links ul li a:hover {
  color: #3498db;
}

.newsletter-form {
  display: flex;
  flex-direction: column;
}

.newsletter-form input {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: none;
}

.newsletter-form button {
  padding: 10px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.newsletter-form button:hover {
  background-color: #2980b9;
}
