/* App.css or index.css */
body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.6;
}

h1, h2, h3 {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  margin: 0;
}
