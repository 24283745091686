.home {
  font-family: 'Roboto', sans-serif;
}

/* Hero Section */
.hero {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}

.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.hero-content {
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 8px;
}

.cta-button {
  background-color: #3498db;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #2980b9;
}

/* Features Section */
.features {
  padding: 50px 20px;
  text-align: center;
}

.feature-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 30px;
}

.feature-card {
  width: 30%;
  text-align: left;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
}

.feature-card img {
  width: 100%;
  border-radius: 8px;
}

/* Testimonials Section */
.testimonials {
  background-color: #ecf0f1;
  padding: 50px 20px;
  text-align: center;
}

.testimonial-slider .testimonial-card {
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 0 auto;
}

/* Contact Section */
.contact {
  padding: 50px 20px;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero {
    height: 80vh;
  }

  .cta-button {
    font-size: 1rem;
    padding: 8px 16px;
  }

  .feature-card {
    width: 45%;
  }

  .testimonials,
  .contact {
    padding: 30px 15px;
  }
}

@media (max-width: 480px) {
  .hero {
    height: 60vh;
  }

  .feature-card {
    width: 100%;
    margin-bottom: 20px;
  }

  .cta-button {
    font-size: 0.9rem;
    padding: 6px 12px;
  }

  .hero-content {
    padding: 15px;
  }
}
