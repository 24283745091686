/* Solutions.css */

.solutions-page {
  padding: 20px;
}

.hero-section {
  background: linear-gradient(to right, #007bff, #00d2ff);
  color: white;
  text-align: center;
  padding: 60px 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}

.hero-section h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  animation: fadeIn 1.5s ease-out;
}

.hero-section p {
  font-size: 1.2rem;
  line-height: 1.5;
}

.solutions-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.solution-item {
  width: 100%;
  max-width: 350px;
  margin: 15px;
  text-align: center;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.solution-icon {
  color: #007bff;
  margin-bottom: 15px;
}

.solution-details {
  padding: 10px;
}

.solution-details ul {
  list-style-type: none;
  padding: 0;
}

.solution-details li {
  margin-bottom: 10px;
}

.read-more-button {
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.read-more-button:hover {
  background-color: #0056b3;
}

.footer-spacing {
  margin-bottom: 100px; /* Adjust based on footer height */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Responsive Styles */

/* For tablets and below */
@media (max-width: 768px) {
  .hero-section {
    padding: 40px 20px;
  }

  .hero-section h1 {
    font-size: 2rem;
  }

  .hero-section p {
    font-size: 1rem;
  }

  .solutions-container {
    flex-direction: column;
    align-items: center;
  }

  .solution-item {
    width: 90%;
    max-width: none;
    margin: 10px 0;
  }

  .read-more-button {
    padding: 8px 16px;
    font-size: 1rem;
  }
}

/* For mobile devices */
@media (max-width: 480px) {
  .hero-section {
    padding: 30px 10px;
  }

  .hero-section h1 {
    font-size: 1.5rem;
  }

  .hero-section p {
    font-size: 0.9rem;
  }

  .solution-item {
    padding: 15px;
  }

  .read-more-button {
    padding: 6px 14px;
    font-size: 0.9rem;
  }
}
